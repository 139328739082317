import React from 'react';
import { useMatch, useResolvedPath, Link, useNavigate } from "react-router-dom";
import { getUser, resetUserSession } from './service/AuthService'

const Admin = () => {
   const navigate = useNavigate();

    const user = getUser();
    const name = user !== 'undefined' && user ? user.firstName : '';

    const logoutHandler = () => {
        resetUserSession();
        navigate('/login');
    }
 return (
    <div className="body">
        Hello, {name}!  You have been logged in. Currently, your session should last for at least 6 days. After 6 days, you will be prompted to log in again. This is temporary to ensure you have a good user experience while we work out any issues with the new timeclock process.
        <br />
        <div className="app-button">
            <ul>
            <CustomLink to="/timeclock">Time Clock</CustomLink>
            </ul>
        </div>
        <br />
        <input type="button" value="Logout" onClick={logoutHandler} />
    </div>
 )   
}


function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
  
    return (
      <li className={isActive ? "active" : ""}>
        <Link to={to} {...props}>
          {children}
        </Link>
      </li>
    )
  }

  
export default Admin;