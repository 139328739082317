import { Route, Routes } from "react-router-dom";
import Home from "./Home"
import Register from "./Register"
import Login from "./Login"
import Admin from "./Admin"
import { NotFound } from "./NotFound"
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import React, { useState, useEffect } from "react";
import { getUser, getToken, setUserSession, resetUserSession } from "./service/AuthService"
import axios from "axios"
import './styles.css'
import Navbar from "./Navbar"
import Timeclock from "./Timeclock"
import CustomerProfile from "./CustomerProfile"
import Geolocation from "./Geolocation"

const verifyTokenUrl = 'https://ki3njsqnlk.execute-api.us-east-1.amazonaws.com/prod/verify';

function App() {

  const [isAuthenticating, setAuthenticating] = useState(true)

  useEffect(() => {
    const token = getToken()
    if ( token === 'undefined' || token === undefined || token === null || !token ) {
      return
    }

    const requestConfig = {
      headers: {
        'x-api-key': 'ooISsC7lbhaLPpvaSst179Xp1cuGMahv8JY717FV'
      }
    }
    const requestBody = {
      user: getUser(),
      token: token
    }

    axios.post(verifyTokenUrl, requestBody, requestConfig).then(response => {
      setUserSession(response.data.user, response.data.token)
      setAuthenticating(false)
    }).catch(() => {
      resetUserSession()
      setAuthenticating(false)
    })
  }, [])

  const token = getToken()
  if (isAuthenticating && token) {
    return <div className="content">Authenticating...</div>
  }

  return (
    <>
      <Navbar />
    <div className="App">
      <div>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/register" element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }/>
          <Route path="/login" element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }/>
          <Route path="/admin" element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }/>
          <Route path="/timeclock" element={
            <PrivateRoute>
              <Timeclock />
            </PrivateRoute>
          }/>
          <Route path="/location" element={
            <PrivateRoute>
              <Geolocation />
            </PrivateRoute>
          }/>
          <Route path="/customerprofile" element={
            <PrivateRoute>
              <CustomerProfile />
            </PrivateRoute>
          }/>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
    </>
  );
}

export default App;
