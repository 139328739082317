import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { setUserSession } from './service/AuthService'

const loginUrl = 'https://ki3njsqnlk.execute-api.us-east-1.amazonaws.com/prod/login';

const Login = () => {
   const navigate = useNavigate();
   
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const submitHandler = (event) => {
        event.preventDefault();
        if ( username.trim() === '' || password.trim() === '' ) {
            setErrorMessage('Username and password is required');
            return;
        }
        setErrorMessage(null);

        const requestConfig = {
            headers: {
                'x-api-key': 'ooISsC7lbhaLPpvaSst179Xp1cuGMahv8JY717FV'
            }
        }
        const requestBody = {
            username: username,
            password: password
        }
        axios.post(loginUrl, requestBody, requestConfig)
        .then(response => { 
            setUserSession(response.data.user, response.data.token);
            navigate('/admin');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Sorry, backend server is down. Please try again later.');
            }
        })
    }
 return (
    <div className="auth-form-container">
        <form onSubmit={submitHandler}>
            <div className="form-heading">
                <h5>Login</h5>
            </div>
            Username: <input type="text" value={username} onChange={event => setUsername(event.target.value)} /> <br/>
            Password: <input type="password" value={password} onChange={event => setPassword(event.target.value)} /> <br/>
            <input type="submit" value="Login" />
        </form>
        {errorMessage && <p className="message">{errorMessage}</p>}
    </div>
 )   
}

export default Login;