import React from 'react'
//import './styles.css'

const Home = () => {
   return (
   <>
   <div className="form-heading">
         <h2>Welcome to the Pearson & Company Portal</h2>
      </div>
      <div className="home-container">
         <p className="subtitle">You will need to login using the Admin in the navigation bar above.</p>
         <p>Please report any issues, bugs, or concerns to the person that sent this site to you.</p>
         Thank you for your help.
         -jk
      </div>
      </>
   )
}

export default Home