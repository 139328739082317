import { useState, useEffect } from 'react';

function Geolocation() {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [accuracy, setAccuracy] = useState(null);
  const [altitude, setAltitude] = useState(null);
  const [altitudeAccuracy, setAltitudeAccuracy] = useState(null);
  const [heading, setHeading] = useState(null);
  const [speed, setSpeed] = useState(null);
  const [timestamp, setTimestamp] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setTimestamp(position.timestamp);
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setAccuracy(position.coords.accuracy);
          setAltitude(position.coords.altitude);
          setAltitudeAccuracy(position.coords.altitudeAccuracy);
          setHeading(position.coords.heading);
          setSpeed(position.coords.speed);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <div>
      <h1>Your Location:</h1>
      <p>Latitude: {latitude}</p>
      <p>Longitude: {longitude}</p>
      <p>+/- {accuracy} meters</p>
      <p>altitude: {altitude}</p>
      <p>altitudeAccuracy: {altitudeAccuracy}</p>
      <p>heading: {heading}</p>
      <p>speed: {speed}</p>
      <p>timestamp: {timestamp}</p>
    </div>
  );
}

export default Geolocation