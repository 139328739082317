import React, { useState } from 'react';
import axios from 'axios';

const registerUrl = 'https://ki3njsqnlk.execute-api.us-east-1.amazonaws.com/prod/register';

const Register = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [hireDate, setHireDate] = useState('');
    const [homeAddress, setHomeAddress] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(null);

    const submitHandler = (event) => {
        event.preventDefault();
        if (firstName.trim() === '' || lastName.trim() === '' || email.trim() === '' || mobile.trim() === '' || employeeId.trim() === '' || username.trim() === '' || password.trim() === '' ) {
            setMessage('All fields are required');
            return;
        }
        setMessage(null);
        const requestConfig = {
            headers: {
                'x-api-key': 'ooISsC7lbhaLPpvaSst179Xp1cuGMahv8JY717FV'
            }
        }
        const requestBody = {
            firstName: firstName,
            lastName: lastName,
            mobile: mobile,
            employeeId: employeeId,
            birthDate: birthDate,
            homeAddress: homeAddress,
            hireDate: hireDate,
            email: email,
            username: username,
            password: password
        }
        axios.post(registerUrl, requestBody, requestConfig).then(response => {
            setMessage('Registration Successful');
        }).catch(error => {
            if (error.response.status === 401) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Sorry, backend server is down. Please try again later.');
            }
        })
    }

 return (
    <div className="auth-form-container">
        <form onSubmit={submitHandler}>
            <div className="form-heading">
                <h5>Register</h5>
            </div>
            First Name: <input type="text" value={firstName} onChange={event => setFirstName(event.target.value)} /> <br/>
            Last Name: <input type="text" value={lastName} onChange={event => setLastName(event.target.value)} /> <br/>
            Email: <input type="text" value={email} onChange={event => setEmail(event.target.value)} /> <br/>
            Mobile: <input type="text" value={mobile} onChange={event => setMobile(event.target.value)} /> <br/>
            Employee ID: <input type="text" value={employeeId} onChange={event => setEmployeeId(event.target.value)} /> <br/>
            Birthdate: <input type="text" value={birthDate} onChange={event => setBirthDate(event.target.value)} /> <br/>
            Home Address: <input type="text" value={homeAddress} onChange={event => setHomeAddress(event.target.value)} /> <br/>
            Hire Date: <input type="text" value={hireDate} onChange={event => setHireDate(event.target.value)} /> <br/>
            Username: <input type="text" value={username} onChange={event => setUsername(event.target.value)} /> <br/>
            Password: <input type="password" value={password} onChange={event => setPassword(event.target.value)} /> <br/>
            <input className="button" type="submit" value="Register" />
        </form>
        {message && <p className="message">{message}</p>}
    </div>
 )   
}

export default Register;