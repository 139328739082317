import React, { useState } from 'react';
import axios from 'axios';

const addCustomerUrl = 'https://ki3njsqnlk.execute-api.us-east-1.amazonaws.com/prod/addcustomer';

const CustomerProfile = () => {

    const [customerId, setCustomerId] = useState('');
    
    const [firstName, setFirstName] = useState('');
   const [lastName, setLastName] = useState('');
   
   const [complex, setComplex] = useState('');
   const [address, setAddress] = useState('');
   const [city, setCity] = useState('');
   const [zipCode, setZipCode] = useState('');
   const [homeNumber, setHomeNumber] = useState('');
   const [workNumber, setWorkNumber] = useState('');
   const [mobileNumber, setMobileNumber] = useState('');

   const [serviceBegin, setServiceBegin] = useState('');
   const [serviceFee, setServiceFee] = useState('');
   const [designer, setDesigner] = useState('');
   const [serviceDay, setServiceDay] = useState('');
   const [technican, setTechnican] = useState('');
   const [petNames, setPetNames] = useState('');

   const [estimatedTime, setEstimatedTime] = useState('');
   const [numberPlants, setNumberPlants] = useState('');
   const [bromeliadInfo, setBromeliadInfo] = useState('');

   const [entryInstructions, setEntryInstructions] = useState('');
   const [alarmInformation, setAlarmInformation] = useState('');
   const [waterLocation, setWaterLocation] = useState('');
   const [directions, setDirections] = useState('');
   const [summerStatus, setSummerStatus] = useState('');
   const [comments, setComments] = useState('');

   const [email, setEmail] = useState('');
   const [username, setUsername] = useState('');
   const [password, setPassword] = useState('');
   const [message, setMessage] = useState(null);

   const submitHandler = (event) => {
        event.preventDefault();
        if (address.trim() === '' || city.trim() === '' || zipCode.trim() === '' ) {
            setMessage('All fields are required');
            return;
        }
        setMessage(null);
        const requestConfig = {
            headers: {
                'x-api-key': 'ooISsC7lbhaLPpvaSst179Xp1cuGMahv8JY717FV'
            }
        }
      const requestBody = {
        customerId: customerId,

        firstName: firstName,
         lastName: lastName,

         complex: complex,
         address: address,
         city: city,
         zipCode: zipCode,
         homeNumber: homeNumber,
         workNumber: workNumber,
         mobileNumber: mobileNumber,

         serviceBegin: serviceBegin,
         serviceFee: serviceFee,
         designer: designer,
         serviceDay: serviceDay,
         technican: technican,
         petNames: petNames,

         estimatedTime: estimatedTime,
         numberPlants: numberPlants,
         bromeliadInfo: bromeliadInfo,

         entryInstructions: entryInstructions,
         alarmInformation: alarmInformation,
         waterLocation: waterLocation,
         directions: directions,
         summerStatus: summerStatus,
         comments: comments,

         email: email,
         username: username,
         password: password
      }
        axios.post(addCustomerUrl, requestBody, requestConfig).then(response => {
            setMessage('Customer Added Successful');
        }).catch(error => {
            if (error.response.status === 401) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Sorry, backend server is down. Please try again later.');
            }
        })
    }

 return (
    <div className="auth-form-container">
        <form onSubmit={submitHandler}>
            <div className="form-heading">
            <h1>Add Customer Profile</h1>
            </div>
            Customer ID: <input type="text" value={customerId} onChange={event => setCustomerId(event.target.value)} />
            <br/>
            First Name: <input type="text" value={firstName} onChange={event => setFirstName(event.target.value)} />
            Last Name: <input type="text" value={lastName} onChange={event => setLastName(event.target.value)} />
            <br/>
            Complex: <input type="text" value={complex} onChange={event => setComplex(event.target.value)} />
            Address: <input type="text" value={address} onChange={event => setAddress(event.target.value)} />
            <br/>
            City: <input type="text" value={city} onChange={event => setCity(event.target.value)} />
            Zip Code: <input type="text" value={zipCode} onChange={event => setZipCode(event.target.value)} />
            <br/>
            Home Number: <input type="text" value={homeNumber} onChange={event => setHomeNumber(event.target.value)} />
            Work Number: <input type="text" value={workNumber} onChange={event => setWorkNumber(event.target.value)} />
            Mobile Number: <input type="text" value={mobileNumber} onChange={event => setMobileNumber(event.target.value)} />
            <br/>
            Service Begin: <input type="text" value={serviceBegin} onChange={event => setServiceBegin(event.target.value)} />
            Service Fee: <input type="text" value={serviceFee} onChange={event => setServiceFee(event.target.value)} />
            <br/>
            Designer: <input type="text" value={designer} onChange={event => setDesigner(event.target.value)} />
            Service Day: <input type="text" value={serviceDay} onChange={event => setServiceDay(event.target.value)} />
            Technican: <input type="text" value={technican} onChange={event => setTechnican(event.target.value)} />
            <br/>
            Pet Names: <input type="text" value={petNames} onChange={event => setPetNames(event.target.value)} />
            <br/>
            Estimated Time: <input type="text" value={estimatedTime} onChange={event => setEstimatedTime(event.target.value)} />
            Number of Plants: <input type="text" value={numberPlants} onChange={event => setNumberPlants(event.target.value)} />
            Bromeliad Info: <input type="text" value={bromeliadInfo} onChange={event => setBromeliadInfo(event.target.value)} />
            <br/>
            Entry Instructions: <input type="text" value={entryInstructions} onChange={event => setEntryInstructions(event.target.value)} />
            <br/>
            Alarm Information: <input type="text" value={alarmInformation} onChange={event => setAlarmInformation(event.target.value)} />
            <br/>
            Water Location: <input type="text" value={waterLocation} onChange={event => setWaterLocation(event.target.value)} />
            <br/>
            Directions: <input type="text" value={directions} onChange={event => setDirections(event.target.value)} />
            <br/>
            Summer Status: <input type="text" value={summerStatus} onChange={event => setSummerStatus(event.target.value)} />
            <br/>
            Comments: <input type="text" value={comments} onChange={event => setComments(event.target.value)} />
            <br/>
            Email: <input type="text" value={email} onChange={event => setEmail(event.target.value)} />
            Username: <input type="text" value={username} onChange={event => setUsername(event.target.value)} />
            Password: <input type="password" value={password} onChange={event => setPassword(event.target.value)} />
            <br/>
            <input className="button" type="submit" value="Add Customer" />
        </form>
        {message && <p className="message">{message}</p>}
    </div>
 )   
}

export default CustomerProfile