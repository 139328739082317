import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';

const timeclockUrl = 'https://ki3njsqnlk.execute-api.us-east-1.amazonaws.com/prod/timeclock';

// const GetGeoLocation = async () => {
//     const [latitude, setLatitude] = useState(null);
//     const [longitude, setLongitude] = useState(null);
  
//     useEffect(() => {
//       if (navigator.geolocation) {
//         navigator.geolocation.getCurrentPosition(
//           (position) => {
//             setLatitude(position.coords.latitude);
//             setLongitude(position.coords.longitude);
//           },
//           (error) => {
//             console.log(error);
//           }
//         );
//       } else {
//         console.log("Geolocation is not supported by this browser.");
//       }
//     }, []);
//     return (
//         <div>
//           <h1>Your Location:</h1>
//           <p>Latitude: {latitude}</p>
//           <p>Longitude: {longitude}</p>
//         </div>
//       );    
// } 

const Timeclock = () => {
    const [employeeId, setEmployeeId] = useState('');
    const [timestamp, setTimestamp] = useState(null);
    const [clockedDirection, setClockedDirection] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [timeClockMessage, setTimeClockMessage] = useState(null);
    const [skippedLunch, setSkippedLunch] = useState(false);
    
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
  
    const inputReference = useRef(null);

    useEffect(() => {
        inputReference.current.focus();
    }, [])

    function getGeoLocation1() {
        console.log("Geolocation function invoked.");
            if (navigator.geolocation) {
          console.log("navigator.geolocation if statement invoked.");
          navigator.geolocation.getCurrentPosition(
            (position) => {
              console.log("Setting latitude/longitude.");
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          console.log("Geolocation is not supported by this browser.");
        }
    }

    const getGeoLocation2 = async () => {
        console.log("3. Let's try a new promise.")
        try {
            const position = await new Promise((resolve, reject) => {
                console.log("4.1. Start - Resolving currentPosition from the GPS.")
                navigator.geolocation.getCurrentPosition(resolve, reject);
                console.log("4.2. Finish - Resolving currentPosition from the GPS.")
            });
            console.log("5. Setting the geolocation variable.")
            const { latitude, longitude } = await position.coords;
            console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        } catch (error) {
          console.error(error.message);
        } finally {
            console.log("Finally")
        }
        console.log("6. Did I come through on my new promise?")
      };

    function getTimeNow() {
        var timeNow = Date.now()
        setTimestamp(timeNow)
        
        // const dateTimeNow = new Date(timeNow)
    }

    function buttonClockedPressed(clockedInDirection) {
        getGeoLocation2()
        setClockedDirection(clockedInDirection)
        getTimeNow()
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 ) {
            event.preventDefault()
        }
    }

    const submitHandler = (event) => {
        event.preventDefault()

        inputReference.current.focus()
    
        if (employeeId === '' ) {
            setErrorMessage('All frontend fields are required');
            return;
        }
        
        setErrorMessage(null);

        const requestConfig = {
            headers: {
                'x-api-key': 'ooISsC7lbhaLPpvaSst179Xp1cuGMahv8JY717FV'
            }
        }

        const requestBody = {
            employeeId: employeeId,
            timestamp: timestamp,
            clockedDirection: clockedDirection,
            skippedLunch: skippedLunch,
            latitude: latitude,
            longitude: longitude
        }

        axios.post(timeclockUrl, requestBody, requestConfig).then(response => { 
            const dateTime = new Date(timestamp)
            console.log("dateTime.toLocaleString(): " + dateTime.toLocaleString())
    
            setTimeClockMessage('Employee ' + employeeId + ' successfully clocked ' + clockedDirection + ' at ' + dateTime.toLocaleString() + ' Latitude: ' + latitude + ' Longitude: ' + longitude + ' Lunch Skipped: ' + skippedLunch)
            console.log('Employee ' + employeeId + ' successfully clocked ' + clockedDirection + ' at ' + dateTime.toLocaleString() + ' Latitude: ' + latitude + ' Longitude: ' + longitude + ' Lunch Skipped: ' + skippedLunch)

            setEmployeeId('')
            setTimestamp('')
            setClockedDirection('')
            setSkippedLunch(false)
            setLatitude(null)
            setLongitude(null)
    
            setTimeout(() => {
                setTimeClockMessage('')
            }, 5000)

        }).catch(error => {
            if (error.response.status === 401) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("Sorry, backend server is not happy with the garbage we've sent. Please try again later or contact the Administrator.");
            }
        })
    }

 return (
    <div className="auth-form-container">
        <form onSubmit={submitHandler}>
            <div className="form-heading">
                <h5>Time Clock</h5>
            </div>
            {errorMessage && <p className="errorMessage">{errorMessage}</p>}
            {timeClockMessage && <p className="timeClockMessage">{timeClockMessage}</p>}
            Employee ID: <input type="text" onKeyDown={handleKeyDown} ref={inputReference} value={employeeId} onChange={event => setEmployeeId(event.target.value)} />
            <br/>
            * If you skipped lunch, check the box: <input type="checkbox" checked={skippedLunch} onChange={() => setSkippedLunch((prev) => !prev)} />

            <br/>
            <button className="clockin-button" onClick={()=>{buttonClockedPressed("IN")}} type="submit">CLOCK IN</button>
            <button className="clockin-button" onClick={()=>{buttonClockedPressed("OUT")}} type="submit">CLOCK OUT</button>
        </form>
        v0.2
        <br />
        <p>* This is only necessary when you Clock Out at the end of your current shift.</p>
    </div>
    
 )   
}

export default Timeclock;